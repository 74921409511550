import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _551a325b = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _72d0489e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _bb15bdde = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _3fc765c6 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _dcd60e46 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _35ef215e = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _2954c0b9 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _4c6a89ae = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _744fe085 = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _d59eefa8 = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _6c1cc428 = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _59ad9eb0 = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _377051a0 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _bde2b336 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _66ed9b88 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _55295c0b = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _6feb0b4b = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _342122b6 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _251edb60 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _4352c6ff = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _b53b2d90 = () => interopDefault(import('../pages/checkout/transsmart.vue' /* webpackChunkName: "pages/checkout/transsmart" */))
const _ba26b39e = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _442743be = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _3ee63547 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _a78fe714 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _f52ec38a = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _d9d77f4c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _74928ef8 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _956f2588 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _3e57dcbe = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _716c4a7a = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _54fa7a73 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _e3755b40 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _7656a6e8 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _1a2aaf72 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _060504a4 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _eaa00006 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _29cb8e56 = () => interopDefault(import('../pages/review/_slug.vue' /* webpackChunkName: "pages/review/_slug" */))
const _4167f9b6 = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _cf995308 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _63b6ef49 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _9c635a82 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _91d5ca36 = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _4d0a0400 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e78aefe6 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _551a325b,
    children: [{
      path: "",
      component: _72d0489e,
      name: "account___nl"
    }, {
      path: "details",
      component: _bb15bdde,
      name: "account-details___nl"
    }, {
      path: "newsletter",
      component: _3fc765c6,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _dcd60e46,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _35ef215e,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _2954c0b9,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _4c6a89ae,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _744fe085,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _d59eefa8,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _6c1cc428,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _59ad9eb0,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _377051a0,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/cart",
    component: _bde2b336,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _66ed9b88,
    children: [{
      path: "",
      component: _55295c0b,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _6feb0b4b,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _342122b6,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _251edb60,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _4352c6ff,
      name: "checkout-payment___nl"
    }, {
      path: "transsmart",
      component: _b53b2d90,
      name: "checkout-transsmart___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _ba26b39e,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _442743be,
    name: "checkout-success___nl"
  }, {
    path: "/faq",
    component: _3ee63547,
    name: "faq___nl"
  }, {
    path: "/forgot-password",
    component: _a78fe714,
    name: "forgot-password___nl"
  }, {
    path: "/icon",
    component: _f52ec38a,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _d9d77f4c,
    name: "login___nl"
  }, {
    path: "/merken",
    component: _74928ef8,
    name: "brand___nl"
  }, {
    path: "/nieuws",
    component: _956f2588,
    name: "blog-category___nl"
  }, {
    path: "/vestigingen",
    component: _3e57dcbe,
    name: "store-lister___nl"
  }, {
    path: "/blocks/demo",
    component: _716c4a7a,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _54fa7a73,
    name: "forgot-password-reset___nl"
  }, {
    path: "/login/guest",
    component: _e3755b40,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _7656a6e8,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/nieuws/detail/:slug?",
    component: _1a2aaf72,
    name: "blog-detail-slug___nl"
  }, {
    path: "/product/detail/:slug?",
    component: _060504a4,
    name: "product-detail-slug___nl"
  }, {
    path: "/product/lister/*",
    component: _eaa00006,
    name: "product-lister-all___nl"
  }, {
    path: "/review/:slug?",
    component: _29cb8e56,
    name: "review-slug___nl"
  }, {
    path: "/search/:slug?",
    component: _4167f9b6,
    name: "search-slug___nl"
  }, {
    path: "/winkel/:slug?",
    component: _cf995308,
    name: "store-detail-slug___nl"
  }, {
    path: "/nieuws/*",
    component: _63b6ef49,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _9c635a82,
    name: "blog-tag-all___nl"
  }, {
    path: "/merken/*",
    component: _91d5ca36,
    name: "brand-lister-all___nl"
  }, {
    path: "/",
    component: _4d0a0400,
    name: "index___nl"
  }, {
    path: "/*",
    component: _e78aefe6,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
